<template>
  <div class="card card-custom overflow-hidden" style="height: calc(100vh - 170px)">
    <!--begin::Body-->
    <div class="card-body" style="overflow-y: auto">
      <!--begin:Search-->
      <div class="d-flex align-items-center">
        <div class="input-group input-group-solid">
          <div class="input-group-prepend">
          <span class="input-group-text">
            <span class="far fa-search text-muted h4 mb-0"/>
          </span>
          </div>
          <input type="text" class="form-control py-4" :placeholder="$t('placeholder.ticket')">
        </div>
        <div class="flex-shrink-0">
          <button type="button" class="btn btn-success font-weight-bold ml-5 mr-0" @click="$emit('create')">
            {{ $t('btn.create_ticket') }}
          </button>
        </div>
      </div>
      <div class="mt-7">
        <div
            v-for="ticket in ticketsList"
            class="d-flex align-items-center justify-content-between cursor-pointer mb-5"
            @click="$emit('select', ticket)"
        >
          <div class="d-flex align-items-center w-75">
            <div class="symbol symbol-circle symbol-50 mr-3">
              <img alt="Pic" src="media/users/default.jpg">
            </div>
            <div class="d-flex flex-column text-truncate">
              <a href="#" class="text-dark-75 text-truncate text-hover-primary font-weight-bold font-size-lg">{{ ticket.title }}</a>
              <span class="d-inline-block text-truncate text-muted font-size-sm">{{ ticket.description }}</span>
            </div>
          </div>
          <div class="flex-shrink-0 d-flex flex-column align-items-end">
            <span :class="ticket.new_messages !== 0 ? 'label label-danger ml-2' : 'label label-success ml-2'">{{ ticket.new_messages }}</span>
            <span class="text-muted font-weight-bold font-size-sm">{{ ticket.updated_at }}</span>
          </div>
        </div>
      </div>
      <!--end:Users-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    updateData: {
      type: Boolean,
      default: false
    },
    selectedTicket: {
      default: null
    }
  },
  data: () => ({
    intervalId: null
  }),
  beforeMount() {
    this.getData();
    this.intervalId = setInterval(() => {
      this.getData();
    }, 60*1000);
  },
  computed: {
    ...mapGetters({
      ticketsList: 'ticketsStore/LIST'
    })
  },
  watch: {
    updateData(val) {
      if (val) {
        this.getData();
      }
    },
    ticketsList(data) {
      if (data) {
        let newMessages = data.reduce((accumulator, object) => {
          return accumulator + object.new_messages;
        }, 0);

        this.$store.commit('ticketsStore/SET_UNREAD_MESSAGES_COUNT', newMessages);

        if (this.selectedTicket) {
          const newTicket = this.ticketsList.find(el => el.id === this.selectedTicket);
          this.$emit('select', newTicket);
        }
      }
    }
  },
  methods: {
    async getData() {
      await this.$store.dispatch('ticketsStore/GET_LIST');
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
}
</script>
