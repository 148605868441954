<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile offcanvas-mobile-on"
         :class="newTicketFlag || !!selectedTicket ? 'w-350px w-xl-400px test' : 'w-100'"
    >
      <TicketsList
          :update-data="updateTicketsListFlag"
          :selected-ticket="createdTicketID"
          @create="onCreate"
          @select="onSelect"
      />
    </div>
    <div v-if="!selectedTicket && !newTicketFlag" class="offcanvas-mobile-overlay"></div>
    <div v-if="!!selectedTicket" class="flex-row-fluid ml-lg-8">
      <Ticket :ticket="selectedTicket" @loaded="updateTicketsListFlag = true" @close="onClose"/>
    </div>
    <div v-if="newTicketFlag" class="flex-row-fluid ml-lg-8">
      <NewTicket @close="newTicketFlag = false" @ticketCreated="onTicketCreated"/>
    </div>
  </div>
</template>

<script>
import NewTicket from '@/components/support/NewTicket';
import Ticket from '@/components/support/Ticket';
import TicketsList from '@/components/support/TicketsList';

export default {
  components: {
    NewTicket,
    Ticket,
    TicketsList,
  },
  beforeMount() {
    if (this.$route.query?.new) {
      this.newTicketFlag = true;
    }
  },
  data: () => ({
    createdTicketID: null,
    newTicketFlag: false,
    selectedTicket: null,
    updateTicketsListFlag: false,
  }),
  watch: {
    updateTicketsListFlag(val) {
      if (val) {
        setTimeout(() => {
          this.updateTicketsListFlag = false;
        }, 3000);
      }
    },
    selectedTicket(data) {
      if (!!data) {
        this.updateTicketsListFlag = true;
      }
    }
  },
  methods: {
    onSelect(ticket) {
      this.newTicketFlag = false;
      this.selectedTicket = ticket;
    },
    onCreate() {
      this.newTicketFlag = true;
      this.selectedTicket = null;
    },
    onTicketCreated(ticketID) {
      this.createdTicketID = ticketID;
      this.updateTicketsListFlag = true;
    },
    onClose() {
      this.selectedTicket = null;
      this.updateTicketsListFlag = true;
    }
  },
  beforeDestroy() {
    this.$store.commit('ticketsStore/CLEAR_DATA');
  }
}
</script>

<style lang="scss" scoped>
.test {
  @media (max-width: 768px) {
    width: 0 !important;
  }
}
</style>
