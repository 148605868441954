<template>
  <div class="card card-custom overflow-hidden ticket-card" style="height: calc(100vh - 170px)">
    <!--begin::Header-->
    <div class="card-header align-items-center px-4 py-3">
      <div class="card-title">{{ $t('title.create_new_ticket') }}</div>
      <div class="card-toolbar">
        <span class="far fa-times h4 text-muted cursor-pointer mb-0 mr-3" @click="$emit('close')"/>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body" style="overflow-y: auto">
<!--      <InputFormGroup-->
<!--          class="w-100 my-2 title-input"-->
<!--          :placeholder="$t('placeholder.enter_title')"-->
<!--          :value="form.subject"-->
<!--          @input="form.subject = $event.trim()"-->
<!--          @keydown.native.enter="handleEnter"-->
<!--      />-->
      <MultiselectFormGroup
          class="app-view__form-group"
          :placeholder="$t('placeholder.choose_type')"
          :value="typesList.find(el => el.id === form.type_id)"
          :options="typesList"
          @select="form.type_id = $event.id"
          @remove="form.type_id = null"
      />
      <b-form-textarea
          class="form-control px-4"
          v-model="form.description"
          :placeholder="$t('placeholder.type_message')"
          rows="5"
          @keydown.enter="handleEnter"
      ></b-form-textarea>
    </div>
    <div class="card-footer d-flex justify-content-end">
      <button
          type="button"
          class="btn btn-primary btn-md text-uppercase font-weight-bold py-2 px-6"
          :disabled="!isFormValid"
          @click="submitNewTicket"
      >
        {{ $t('btn.create') }}
      </button>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';
import i18n from '@/core/plugins/vue-i18n';

export default {
  components: {
    InputFormGroup,
    MultiselectFormGroup
  },
  data: () => ({
    form: {
      // subject: null,
      type_id: null,
      description: null,
    },
    typesList: [
      {
        id: 1,
        label: i18n.t('ticketTypes.financial_issues'),
      },
      {
        id: 2,
        label: i18n.t('ticketTypes.technical_problem'),
      },
      {
        id: 3,
        label: i18n.t('ticketTypes.booking_questions'),
      },
      {
        id: 4,
        label: i18n.t('ticketTypes.digital_key_issue'),
      },
      {
        id: 5,
        label: i18n.t('ticketTypes.internet_tv_phone'),
      },
      {
        id: 6,
        label: i18n.t('ticketTypes.other'),
      },
    ]
  }),
  // beforeMount() {
  //   this.form.subject = this.$route.query?.title;
  // },
  computed: {
    isFormValid() {
      return !!this.form.type_id && !!this.form.description;
    }
  },
  methods: {
    async submitNewTicket() {
      if (!this.isFormValid) return false;

      const ticketID = await this.$store.dispatch('ticketsStore/CREATE', this.form);
      await this.$emit('ticketCreated', ticketID);
    },
    handleEnter (e) {
      if (!e.ctrlKey) return false;
      this.submitNewTicket();
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ticket-card {
    padding: 0 !important;
    height: calc(100vh - 100px) !important;
  }
}
</style>