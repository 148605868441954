<template>
  <div class="card card-custom overflow-hidden ticket-card" style="height: calc(100vh - 170px)">
    <CardLoader v-if="isLoading" :with-text="false"/>
    <!--begin::Header-->
    <div class="card-header align-items-center min-h-70px h-70px px-4 py-3">
      <div class="text-left flex-grow-1">
        <!--begin::Aside Mobile Toggle-->
        <span class="far fa-comments text-hover-primary d-lg-none h3 mb-0" @click="$emit('close')"></span>
        <!--end::Aside Mobile Toggle-->
      </div>
      <div class="text-center flex-grow-1">
        <div class="text-dark-75 font-weight-bold font-size-h5 truncate-overflow" :title="ticket.title">
          {{ ticket.title }}
        </div>
        <div class="text-muted font-size-sm mt-2">{{ ticket.created_at }}</div>
      </div>
      <div class="d-xs-none text-right flex-grow-1">
        <span class="far fa-times h4 text-muted cursor-pointer mb-0 mr-3" @click="$emit('close')"/>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body" style="overflow-y: auto" ref="cardBody">
      <div data-mobile-height="350">
        <!--begin::Messages-->
        <div class="messages">
          <div v-for="(messages, date) in messagesByDate">
            <div class="d-flex justify-content-center mb-2">
              <span class="label label-inline" style="font-size: 12px">{{ date }}</span>
            </div>
            <!--begin::Message In-->
            <div v-for="message in messages"
                 class="d-flex flex-column mb-5"
                 :class="message.reply ? 'align-items-start' : 'align-items-end'"
            >
              <div class="d-flex align-items-center">
                <div class="text-dark-75 font-weight-bold">
                  {{ message.author }}
                </div>
              </div>
              <div class="max-w-400px">
                <div
                  class="mt-2 rounded p-5 text-dark-50"
                  :class="message.reply ? 'text-left bg-light-success' : 'text-right bg-light-primary'"
                >
                  <div v-if="!message.files.length">{{ message.message }}</div>
                  <div v-else-if="message.message.includes('.pdf')">
                    <a
                      href="#"
                      class="menu-link d-flex align-items-center"
                      @click="onPdfClick(message.files[0].id, message.message)"
                    >
                      <i class="menu-icon fas fa-file-pdf text-danger"></i>
                      <span class="menu-text ml-3">{{ message.message }}</span>
                    </a>
                  </div>
                  <b-img v-else :src="getFileSrc(message.files[0].id)" fluid/>
                </div>
                <div class="text-muted text-right font-size-sm mt-1">{{ message.time }}</div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Messages-->
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Footer-->
    <div class="card-footer">
      <!--begin::Compose-->
      <div class="d-flex align-items-center">
        <b-form-textarea
          class="form-control border-0 p-0"
          v-model="form.message"
          :placeholder="$t('placeholder.type_message')"
          rows="3"
          @keydown.enter="handleEnter" :disabled="ticket.status === 5 ? 'disabled' : null"
        ></b-form-textarea>
        <file-loader-form-group
          class="pl-5 mb-0"
          style="width: 400px"
          :file="form.file"
          required
          @change="addFile($event.target.files)"
        />
        <button
          type="button"
          class="btn btn-primary btn-md text-uppercase font-weight-bold py-2 px-6 ml-5"
          @click="onSubmit" :disabled="ticket.status === 5 ? 'disabled' : null"
        >
          {{ $t('btn.send') }}
        </button>
      </div>
      <!--begin::Compose-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';
import FileLoaderFormGroup from '@/components/elements/form-groups/FileLoaderFormGroup';

export default {
  components: {
    CardLoader,
    FileLoaderFormGroup
  },
  props: {
    ticket: {
      default: null
    }
  },
  data: () => ({
    isLoading: true,
    interval: null,
    currentMessages: null,
    oldMessages: null,
    form: {
      message: '',
      file: null
    },
    files: [],
    imageUrl: ''
  }),
  mounted() {
    this.interval = setInterval(() => {
      this.getMessages();
    }, 20000)
  },
  updated() {
    if (!_.isEqual(this.currentMessages, this.oldMessages)) {
      this.$refs.cardBody.scrollTop = this.$refs.cardBody.scrollHeight;
    }
  },
  computed: {
    ...mapGetters({
      messagesByDate: 'ticketsStore/MESSAGES',
      file: 'ticketsStore/FILE'
    }),
    computedCardBodyHeight() {
      return this.$refs.cardBody.clientHeight;
    },
  },
  watch: {
    ticket: {
      immediate: true,
      handler(ticket) {
        this.$store.commit('ticketsStore/CLEAR_MESSAGES');
        if (ticket) this.getMessages();
      }
    },
    messagesByDate(data, oldData) {
      if (data) this.isLoading = false;
      this.currentMessages = data;

      Object.values(data).map(messages => {
        messages.forEach(message => {
          if (message.files.length && !this.files.find(el => el.id === message.files[0].id)) {
            this.getFile(message);
          }
        })
      })

      this.oldMessages = oldData;
    }
  },
  methods: {
    async getMessages() {
      this.isLoading = false;
      await this.$store.dispatch('ticketsStore/GET_MESSAGES', this.ticket.id).then(() => {
        this.$emit('loaded', this.ticket.id);
      });
    },
    addFile(file) {
      this.form.file = file ? file[0] : null;
    },
    getFile(message) {
      if (!message.files.length) return;
      this.$store.dispatch('ticketsStore/GET_FILE', message.files[0].id)
        .then(response => {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            this.files.push({
              id: message.files[0].id,
              src: reader.result,
              type: message.message.includes('.pdf') ? 'file' : 'image'
            });
          };
        }).catch(error => {
        console.log({error});
      });
    },
    getFileSrc(imageId) {
      return this.files.find(el => el.id === imageId)?.src;
    },
    onPdfClick(id, name) {
      const src = this.getFileSrc(id);
      const downloadLink = document.createElement('a');
      downloadLink.href = src;
      downloadLink.download = name;
      downloadLink.click();
    },
    handleEnter(e) {
      if (!e.ctrlKey) return false;
      this.onSubmit();
    },
    setupFormData() {
      let formData = new FormData();
      formData.set('message', this.form.message);
      if (this.form.file) {
        formData.append('file', this.form.file);
      }

      return formData;
    },
    async onSubmit() {
      const payload = this.setupFormData();
      this.isLoading = true;
      await this.$store.dispatch('ticketsStore/SEND_MESSAGE', {
        ticketID: this.ticket.id,
        dataToSubmit: payload
      }).then(() => {
        this.clearForm();
        this.getMessages();
      });
    },
    clearForm() {
      this.form.message = '';
      this.form.file = null;
    }
  },
  beforeDestroy() {
    this.$store.commit('ticketsStore/CLEAR_MESSAGES');
    clearInterval(this.interval);
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ticket-card {
    padding: 0 !important;
    height: calc(100vh - 100px) !important;
  }
}

.truncate-overflow {
  position: relative;
  max-height: 50px;
  overflow: hidden;
}

.truncate-overflow::before {
  position: absolute;
  bottom: -7px;
  right: calc(100% / 2);
}

.truncate-overflow::after {
  content: '';
  position: absolute;
}
</style>
