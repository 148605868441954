<template>
  <div class="form-group">
    <div v-if="withLabel"
         class="form-group__label-wrapper"
         :class="{required}"
    >
      <label class="form-group__label">{{ label }}</label>
    </div>
    <b-form-file
        class="app-input"
        :class="{error: withError && !file}"
        :value="file"
        :disabled="disabled"
        :placeholder="placeholder"
        :accept="accept"
        drop-placeholder="Drop file here..."
        @change="$emit('change', $event)"
    />
    <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
    <p v-if="withError && !file" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: i18n.t('placeholder.upload_file')
    },
    file: {
      default: null
    },
    accept: {
      type: String,
      default: '.jpeg, .jpg, .png, .pdf'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withLabel: {
      type: Boolean,
      default: true
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t("error.field_is_required")
    },
    helpMessage: {
      type: String,
      default: ''
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
  font-size: 0.9rem;
  color: #f64e60;
}

.app-input {
  height: 38px;
}

.app-input.error {
  border-color: #f64e60;
}
</style>
